<template>
    <div class="complex darkBack">
        <div>棒垒球体育文化中心</div>
        <div class="complexcon">
            <div class="complexcontop">
                <div>
                    <p>{{data.achieve.name}}</p>
                    <div>
                        <countTo :startVal='0' :endVal='data.achieve.number' :duration='3000' :decimals="2"></countTo>
                    </div>
                </div>
                <div>
                    <p>{{data.schedule.name}}</p>
                    <div>
                        <countTo :startVal='0' :endVal='data.schedule.number' :duration='3000'></countTo>%
                    </div>
                </div>
                <div>
                    <p>{{data.area.name}}</p>
                    <div>
                        <countTo :startVal='0' :endVal='data.area.number' :duration='3000'></countTo>万平方米
                    </div>
                </div>
                <div>
                    <p>{{data.investment.name}}</p>
                    <div>
                        <countTo :startVal='0' :endVal='data.investment.number' :duration='3000'></countTo>亿元
                    </div>
                </div>
            </div>
            <div class="complexconbot">
                <div>• 项目进度</div>
                <div class="pleximg">
                    <div><img :src="img.pleximg1" alt=""></div>
                    <div><img :src="img.pleximg2" alt=""></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
    components: { countTo },
    data () {
        return {
            data:{
                achieve:window.setData('体育场馆-点击棒垒球体育文化中心-预计完成时间', {name: "预计完成时间", number: 2021.10}),
                schedule:window.setData('体育场馆-点击棒垒球体育文化中心-完成进度', {name: "完成进度",  number: 23}),
                area:window.setData('体育场馆-点击棒垒球体育文化中心-总建筑面积', {name: "总建筑面积",  number: 16}),
                investment:window.setData('体育场馆-点击棒垒球体育文化中心-总投资约', {name: "总投资约",  number: 18}),

            },

            img:window.setData('体育场馆-点击棒垒球体育文化中心-项目经度', {
                pleximg1:require('../../../assets/images/pic1.png'),
                pleximg2:require('../../../assets/images/pic2.png'),
            }),
        }
    }
}
</script>

<style scoped>
.complex{
    width:5.05rem;
    height:4.84rem;
    padding: 0.2rem;
    position: relative;
    font-size: 0.2rem;
    color:#FFFFFF;
}
.complexcon{
    width:4.50rem;
    margin:auto;
    /* height:4rem;
    border:1px solid red; */
}
.complexcontop{
    display: flex;
    justify-content: space-between;
    flex-wrap:wrap;
    margin-top:0.24rem;
    /* margin:0.24rem 0rem; */
}
.complexcontop>div{
    width:2.0rem;
    height:0.73rem;
    background:url('../../../assets/images/borderBack.png') no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0.24rem;
    text-align: center;
    font-size: 0.14rem;
    color: #A8DAEB;
}
.complexcontop>div>p:nth-child(1){
    margin-top:0.1rem;
}
.complexcontop>div>div{
    color:#20D7FE;
    font-size: 0.26rem;
}
.complexconbot{
    color:#20D7FE;
    font-size: 0.16rem;
}
.pleximg{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top:0.15rem;
}
.pleximg>div{
    width:2.00rem;
    height:1.31rem;
}
.pleximg>div>img{
    width:100%;
    height:100%;
}
</style>