<template>
  <div class="about">
      <div class="swiper-container swiper-no-swiping">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(select, index) in list.select" :key="index">{{select}}</div>
        </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
export default {
    data () {
        return {
            list:window.setData('体育产业-右侧导航', {
                select:['越城区体育中心', '新昌县体育中心', '上虞华通体育馆', '诸暨市体育中心', '中国轻纺城体育中心']
            })
        }
    },
    mounted() {
      new Swiper ('.swiper-container', {
        slidesPerView: 5,
        spaceBetween: 30,
        centeredSlides: true,
        loop: true,
        direction: 'vertical',
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
      })
    },
};
</script>

<style scoped>
.about{
    position: relative;
}
.swiper-container {
    width: 4.00rem;
    height: 5.00rem;
    /* border:1px solid red; */
    position: absolute;
    right: -1.00rem;
    top:1.50rem;
}

.swiper-slide {
    text-align: left;
    padding-left:0.4rem;
    box-sizing: border-box;
    font-size: 0.33rem;
    /* background: #1CE8D860; */
    background-image: linear-gradient(90deg, #1ce8d840 20%,#1ce8d810 80%);
    border-radius: 0.60rem;
    /* Center slide text vertically */
    display: flex;
    /* justify-content: center; */
    align-items: center;
    transition: 300ms;
    transform: scale(0.6);
    color: #FFFFFF;
    cursor: default;
    border:0.02rem solid #1ce8d8;
    opacity: 0.5;
}

.swiper-slide-active,
.swiper-slide-duplicate-active {
    transform: scale(1);
    opacity: 1;
}


.swiper-slide-next,.swiper-slide-prev {
    transform: scale(0.8);
    opacity: 0.7;
}
</style>